import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import PQScore from './PQScore';
import Button from '../Page/Button';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';

class AssessmentResultPage extends Component {
  UNSAFE_componentWillMount() {
    const {
      pqStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    pqStore.loadAssessmentResults(resultId);
  }

  handleModalClose = () => {
    this.props.pqStore.preliminaryScoreAlerted = true;
  };

  render() {
    const {
      pqStore,
      history,
      match: {
        params: {resultId}
      }
    } = this.props;
    const {pending, data} = pqStore.result;
    const pq = Math.round(data.pq);

    if (pending) {
      return <LoadingPage />;
    }

    if (!pqStore.preliminaryScoreAlerted) {
      return (
        <AssessmentInfoPage
          image={<div className='img-assessment-instructions' />}
          key='alert'
          title='Preliminary Score'
          text={
            <>
              Your current score is considered preliminary and not certified. You can be more
              certain of your score accuracy once you’ve had 3 measurements following “typical”
              24-hour periods in the space of two weeks.
            </>
          }
          button={
            <Button
              label='Okay, got it'
              tabIndex={0}
              onClick={this.handleModalClose}
              onEnter={this.handleModalClose}
            />
          }
        />
      );
    }

    const handleMoveToPqMeaningResultsPage = () => {
      history.push(`/pq/results/${resultId}/pq_meaning`);
    };

    return (
      <AssessmentInfoPage
        image={<div className='img-pq-score' />}
        wide
        text={
          <>
            <div className='flex flex-col-reverse lg:items-center lg:flex-row pb-26 border-solid border-b border-black'>
              <div className='flex-1'>
                <div className='heading-3'>Assessment score</div>
              </div>
              <div className='mb-26 lg:mb-0'>
                <PQScore score={pq} />
              </div>
            </div>
            <div className='py-32 border-solid border-b border-black'>
              Your score indicates that outside neutral times, your mind:
              <div className='flex'>
                <div className='flex-1 mr-16 mt-16'>
                  <div className='heading-4'>
                    serves you {pq}%<br className='hidden lg:inline' /> of time
                  </div>
                  <div className='body-sm mt-8'>
                    empathy, gratitude, curiosity, joyful creativity, calm, etc.
                  </div>
                </div>
                <div className='flex-1 ml-16 mt-16'>
                  <div className='heading-4'>
                    sabotages you {100 - pq}%<br className='hidden lg:inline' /> of the time
                  </div>
                  <div className='body-sm mt-8'>
                    stress, anxiety, frustration, anger, disappointment, shame, guilt, etc.
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-32'>
              <Button
                label='Aren’t negative emotions useful?'
                tabIndex={0}
                className='w-full'
                onClick={handleMoveToPqMeaningResultsPage}
                onEnter={handleMoveToPqMeaningResultsPage}
              />
            </div>
          </>
        }
      />
    );
  }
}

export default inject('pqStore')(observer(AssessmentResultPage));
