import React from 'react';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import Input from '../Form/Input';
import Select from '../Form/Select';
import countries from '../../const/countries';
import states from '../../const/states';
import timezones from '../../const/timezones';
import FormikField from '../Form/FormikField';
import Radio from '../Form/Radio';
import emailRegex from '../../const/emailRegex';
import Phone from '../Form/Phone';
import {isRestrictedEmail} from '../../utils/isRestrictedEmail';

export const applicationFields = [
  'type',
  'first_name',
  'last_name',
  'email',
  'phone',
  'website',
  'linkedin',
  'country',
  'state',
  'timezone',
  'why',
  'description',
  'certificate',
  'current_clients',
  'annual_revenue',
  'company_name',
  'job_title',
  'job_people_count',
  'sms_enabled',
  'referrer',
  'coaching_school',
  'opted_in'
];

const ApplicationFormFields = ({
  update,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  hiddenFields = []
}) => {
  return (
    <div className='mt-30'>
      <FormikField label='First Name*' name='first_name' {...{errors, touched, hiddenFields}}>
        <Input
          type='text'
          name='first_name'
          placeholder='Leia'
          className='w-full'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.first_name}
        />
      </FormikField>

      <FormikField label='Last Name*' name='last_name' {...{errors, touched, hiddenFields}}>
        <Input
          type='text'
          name='last_name'
          placeholder='Organa'
          className='w-full'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.last_name}
        />
      </FormikField>

      <FormikField
        label='Email Address*'
        name='email'
        hint='Make sure to use the email address you will use if approved. You will need to re-apply if you change your email address.'
        {...{errors, touched, hiddenFields}}
      >
        <Input
          type='email'
          name='email'
          placeholder='kylosmama@tatooine.com'
          className='w-full'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          disabled={update}
        />
      </FormikField>

      <FormikField
        label='Phone Number*'
        className='mt-40'
        name='phone'
        {...{errors, touched, hiddenFields}}
      >
        <Input
          type='text'
          name='phone'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          placeholder='(555) 867-5309'
          defaultCountry='US'
          component={Phone}
        />
      </FormikField>

      <FormikField label='Linkedin Profile*' name='linkedin' {...{errors, touched, hiddenFields}}>
        <Input
          type='text'
          name='linkedin'
          placeholder='https://www.linkedin.com/in/generalleia'
          className='w-full'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.linkedin}
        />
      </FormikField>

      <FormikField
        label='Current Country of Residence*'
        name='country'
        {...{errors, touched, hiddenFields}}
      >
        <Select
          items={countries}
          isSearchable
          onSelect={(value) => setFieldValue('country', value)}
          value={values.country}
        />
      </FormikField>

      {values.country === 'US' ? (
        <>
          <FormikField
            label='Current State of Residence*'
            name='state'
            {...{errors, touched, hiddenFields}}
          >
            <Select
              items={states}
              isSearchable
              onSelect={(value) => setFieldValue('state', value)}
              value={values.state}
            />
          </FormikField>
        </>
      ) : null}

      <FormikField label='Time Zone*' name='timezone' {...{errors, touched, hiddenFields}}>
        <Select
          items={timezones.map((timezone) => ({
            id: timezone.value,
            value: `(GMT ${timezone.offset}) ${timezone.label}`
          }))}
          isSearchable
          onSelect={(value) => setFieldValue('timezone', value)}
          value={values.timezone}
        />
      </FormikField>

      <FormikField
        label='Why are you applying for the PQ Coaching Grant Program?*'
        name='why'
        {...{errors, touched, hiddenFields}}
      >
        <Input
          component='textarea'
          rows={3}
          name='why'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.why}
          placeholder='What are your biggest goals and challenges as a coach? Tell us a little about how receiving the PQ Coaching Grant will help you.'
          className='w-full'
        />
      </FormikField>

      <FormikField
        label='Which of these best describes you as a coach?*'
        name='description'
        {...{errors, touched, hiddenFields}}
      >
        <Radio
          name='description'
          value='self_employed'
          onChange={handleChange}
          selectedValue={values.description}
          label='Self-Employed  (I run my own coaching business as my primary source of income)'
        />
        <Radio
          name='description'
          value='side_hustle'
          onChange={handleChange}
          selectedValue={values.description}
          label='Side-Hustle (I have a day-job and provide coaching during my off hours)'
        />
        <Radio
          name='description'
          value='employee'
          onChange={handleChange}
          selectedValue={values.description}
          label='Employee (I work for a company and my role involves coaching)'
        />
      </FormikField>

      {['self_employed', 'side_hustle'].includes(values.description) ? (
        <>
          <FormikField
            label='Are you certified by the International Coach Federation (ICF)?*'
            name='certificate'
            {...{errors, touched, hiddenFields}}
          >
            <Radio
              name='certificate'
              value='acc'
              onChange={handleChange}
              selectedValue={values.certificate}
              label='Yes, I’m an Associate Certified Coach (ACC)'
            />
            <Radio
              name='certificate'
              value='pcc'
              onChange={handleChange}
              selectedValue={values.certificate}
              label='Yes, I’m a Professional Certified Coach (PCC)'
            />
            <Radio
              name='certificate'
              value='mcc'
              onChange={handleChange}
              selectedValue={values.certificate}
              label='Yes, I’m a Master Certified Coach (MCC)'
            />
            <Radio
              name='certificate'
              value='in_process'
              onChange={handleChange}
              selectedValue={values.certificate}
              label={"No, but I'm in the process of being certified"}
            />
            <Radio
              name='certificate'
              value='no'
              onChange={handleChange}
              selectedValue={values.certificate}
              label='No, I am not certified'
            />
          </FormikField>

          <FormikField
            label='How many paying clients do you currently coach?*'
            name='current_clients'
            {...{errors, touched, hiddenFields}}
          >
            <Radio
              name='current_clients'
              value='0'
              onChange={handleChange}
              selectedValue={values.current_clients}
              label='None'
            />
            <Radio
              name='current_clients'
              value='1-4'
              onChange={handleChange}
              selectedValue={values.current_clients}
              label='1 - 4'
            />
            <Radio
              name='current_clients'
              value='5-9'
              onChange={handleChange}
              selectedValue={values.current_clients}
              label='5 - 9'
            />
            <Radio
              name='current_clients'
              value='10-19'
              onChange={handleChange}
              selectedValue={values.current_clients}
              label='10 - 19'
            />
            <Radio
              name='current_clients'
              value='20-30'
              onChange={handleChange}
              selectedValue={values.current_clients}
              label='20 - 30'
            />
            <Radio
              name='current_clients'
              value='>30'
              onChange={handleChange}
              selectedValue={values.current_clients}
              label='Over 30'
            />
          </FormikField>

          <FormikField
            label='What is your annual coaching revenue?*'
            name='annual_revenue'
            {...{errors, touched, hiddenFields}}
          >
            <Select
              items={[
                {id: '0', value: 'None'},
                {id: '<$10,000', value: 'Less than $10,000'},
                {id: '$10,000-$49,999', value: '$10,000 - $49,999'},
                {id: '$50,000-$100,000', value: '$50,000 to $100,000'},
                {id: '>$100,000', value: 'Over $100,000'}
              ]}
              isSearchable
              onSelect={(value) => setFieldValue('annual_revenue', value)}
              value={values.annual_revenue}
            />
          </FormikField>
        </>
      ) : null}

      {values.description === 'employee' ? (
        <>
          <FormikField
            label='Company Name*'
            name='company_name'
            {...{errors, touched, hiddenFields}}
          >
            <Input
              type='text'
              name='company_name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company_name}
              placeholder='Acme, Inc.'
              className='w-full'
            />
          </FormikField>

          <FormikField label='Job Title*' name='job_title' {...{errors, touched, hiddenFields}}>
            <Input
              type='text'
              name='job_title'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.job_title}
              placeholder='Performance Coach'
              className='w-full'
            />
          </FormikField>

          <FormikField
            label='How many people do you coach as part of your job?*'
            name='job_people_count'
            {...{errors, touched, hiddenFields}}
          >
            <Select
              items={[
                {id: '0', value: 'None'},
                {id: '1-4', value: '1 - 4'},
                {id: '5-9', value: '5 - 9'},
                {id: '10-19', value: '10 - 19'},
                {id: '20-30', value: '20 - 30'},
                {id: '>30', value: 'Over 30'}
              ]}
              isSearchable
              onSelect={(value) => setFieldValue('job_people_count', value)}
              value={values.job_people_count}
            />
          </FormikField>
        </>
      ) : null}

      <FormikField
        label='How did you hear about the PQ Coaching Grant?'
        name='referrer'
        {...{errors, touched, hiddenFields}}
      >
        <Select
          items={[
            {id: 'friend', value: 'From a friend or colleague'},
            {id: 'search', value: 'Google / web search'},
            {id: 'linkedin', value: 'LinkedIn'},
            {id: 'facebook', value: 'Facebook'},
            {id: 'other_social_media', value: 'Other social media'},
            {id: 'coaching_school', value: 'Coaching school'},
            {id: 'other', value: 'Other'}
          ]}
          onSelect={(value) => setFieldValue('referrer', value)}
          value={values.referrer}
        />
      </FormikField>

      {values.referrer === 'coaching_school' ? (
        <>
          <FormikField
            label='Which Coaching School?'
            name='coaching_school'
            {...{errors, touched, hiddenFields}}
          >
            <Select
              items={[
                {id: 'icf', value: 'ICF'},
                {id: 'cti', value: 'CTI'},
                {id: 'ipec', value: 'iPEC'},
                {id: 'nbhwc', value: 'NBHWC'},
                {id: 'other', value: 'Other'}
              ]}
              onSelect={(value) => setFieldValue('coaching_school', value)}
              value={values.coaching_school}
            />
          </FormikField>
        </>
      ) : null}

      <br />

      <div>
        <input
          type='checkbox'
          id='sms_enabled'
          name='sms_enabled'
          onChange={handleChange}
          value='true'
          checked={values.sms_enabled}
          className='mr-10'
        />
        <label htmlFor='sms_enabled'>
          Send me text message notifications when my application status is approved
        </label>
      </div>

      <br />
    </div>
  );
};

export const validateForm = (values) => {
  const errors = {};
  const requiredFields = [
    'is_coach_confirmation',
    'requirements_read_confirmation',
    'first_name',
    'last_name',
    'email',
    'phone',
    'linkedin',
    'country',
    'why',
    'description'
  ];

  if (values.country === 'US') {
    requiredFields.push('state');
  }

  switch (values.description) {
    case 'employee':
      requiredFields.push('company_name');
      requiredFields.push('job_title');
      requiredFields.push('job_people_count');
      requiredFields.push('timezone');
      break;
    case 'google_adwords':
    case 'linkedin_ad':
      break;
    default:
      requiredFields.push('certificate');
      requiredFields.push('current_clients');
      requiredFields.push('annual_revenue');
      requiredFields.push('timezone');
  }

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'This field is required';
    }
  });

  if (values.email && isRestrictedEmail(values.email)) {
    errors.email =
      'For a more reliable delivery of results, we recommend using your personal email address.\n' +
      'Some corporate or institutional email systems might block or filter our emails.';
  }

  if (values.email && !emailRegex.test(values.email)) {
    errors.email = 'Must be a valid email address';
  }

  if (values.phone && !isPossiblePhoneNumber(values.phone)) {
    errors.phone = 'Must be a valid phone number';
  }

  if (Object.keys(errors).length > 0) {
    errors.form = 'Please fix validation errors';
  }

  return errors;
};

export default ApplicationFormFields;
