import React, {Component} from 'react';
import QuestionButton from './QuestionButton';

export default class Question extends Component {
  handleAnswer = (answer) => {
    this.props.onAnswer(answer, true);
  };

  render() {
    const {question, labels, no, answer, total} = this.props;

    return (
      <div className='relative overflow-hidden pb-30 lg:pb-83 pt-13 lg:pt-0'>
        <div className='small-type'>
          {no} / {total}
        </div>
        <div className='body-lg pt-20'>{question.text}</div>
        <div className='flex flex-row items-center pt-67 pl-3 pr-3'>
          <div className='flex-initial'>
            <QuestionButton
              tabIndex={1}
              onAnswer={this.handleAnswer}
              answer={0}
              active={answer === 0}
            />
          </div>
          <div className='flex-1'>
            <div className='border border border-dashed' />
          </div>
          <div className='flex-initial'>
            <QuestionButton
              tabIndex={2}
              onAnswer={this.handleAnswer}
              answer={1}
              active={answer === 1}
            />
          </div>
          <div className='flex-1'>
            <div className='border border border-dashed' />
          </div>
          <div className='flex-initial'>
            <QuestionButton
              tabIndex={3}
              onAnswer={this.handleAnswer}
              answer={2}
              active={answer === 2}
            />
          </div>
          <div className='flex-1'>
            <div className='border border border-dashed' />
          </div>
          <div className='flex-initial'>
            <QuestionButton
              tabIndex={4}
              onAnswer={this.handleAnswer}
              answer={3}
              active={answer === 3}
            />
          </div>
          <div className='flex-1'>
            <div className='border border border-dashed' />
          </div>
          <div className='flex-initial'>
            <QuestionButton
              tabIndex={5}
              onAnswer={this.handleAnswer}
              answer={4}
              active={answer === 4}
            />
          </div>
        </div>
        <div className='flex flex-row justify-center pt-15 lg:pt-8'>
          {labels.map((label, i) => renderLabel(label, i, labels.length))}
        </div>
      </div>
    );
  }
}

const renderLabel = (label, i, total) => {
  const isFirst = i === 0;
  const isLast = i === total - 1;
  const align = isFirst ? 'left' : isLast ? 'right' : 'center';
  return (
    <React.Fragment key={`${i} - ${label}`}>
      <div className={`flex-initial text-${align} xsmall-type lg:small-type whitespace-pre`}>
        {label}
      </div>
      {isLast ? null : <div className='flex-1' />}
    </React.Fragment>
  );
};
