import React from 'react';
import ProgressBar from './ProgressBar';

const Progress = ({current, total, className = ''}) => (
  <div className={`flex w-full items-center ${className}`}>
    <div className='flex-initial small-type w-48'>{Math.round(((current - 1) / total) * 100)}%</div>
    <ProgressBar className='flex-1' position={(current - 1) / total} />
  </div>
);

export default Progress;
